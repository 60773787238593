@charset "UTF-8";
/* ------ FONT ------ */
@font-face {
  font-family: 'Palatino Linotype';
  src: url("/shop/ws2/41002/files/Font/PalatinoLinotype-Roman.woff2") format("woff2"), url("/shop/ws2/41002/files/Font/PalatinoLinotype-Roman.woff") format("woff"), url("/shop/ws2/41002/files/Font/PalatinoLinotype-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Palatino Linotype';
  src: url("/shop/ws2/41002/files/Font/PalatinoLinotype-Bold.woff2") format("woff2"), url("/shop/ws2/41002/files/Font/PalatinoLinotype-Bold.woff") format("woff"), url("/shop/ws2/41002/files/Font/PalatinoLinotype-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Palatino Linotype';
  src: url("/shop/ws2/41002/files/Font/PalatinoLinotype-Italic.woff2") format("woff2"), url("/shop/ws2/41002/files/Font/PalatinoLinotype-Italic.woff") format("woff"), url("/shop/ws2/41002/files/Font/PalatinoLinotype-Italic.ttf") format("truetype");
  font-weight: italic;
  font-style: normal; }

/* ------ MAIN CONTENT ------ */
.bountiful--sidebar .tws-vertical-menu-light .tws-vertical-menu-light--title {
  font-size: 12px; }

.bountiful--sidebar .tws-vertical-menu-light .tws-vertical-menu-light-root-level li a {
  font-size: 11px; }

body {
  color: #000;
  font-family: 'Palatino Linotype', serif;
  letter-spacing: 0.5px;
  text-rendering: auto;
  font-size: 12px; }

@media only screen and (min-width: 992px) {
  .bountiful--start-page .bountiful--component-container .hero-img .tws-content-box--table {
    padding-top: 80px; }
  .hero-img .tws-content-box--first-line {
    font-size: 25px !important; }
  .hero-img .tws-content-box--second-line {
    font-size: 19px !important; } }

.opc-checkout--related .tws-article-list--list .tws-article-list--product-image {
  border: none; }

.bountiful--header .bountiful--header-wrapper,
.bountiful--body .bountiful--body-wrapper,
.bountiful--footer > .container {
  max-width: 1040px; }

.tws-article-list--list .tws-list--type-grid .thumbnail,
.tws-article-list--list .tws-list--type-grid .thumbnail .tws-img--wrapper {
  border: none; }

.using-mouse .tws-article-list--list .tws-list--type-grid .thumbnail:hover {
  box-shadow: none; }

.bountiful--body .bountiful--body-wrapper .bountiful--main .bountiful--main-wrapper {
  box-shadow: none; }

.tws-breadcrumbs a {
  color: #000; }

.tws-articlegroups .thumbnail:hover,
.tws-articlegroup-list .thumbnail:hover {
  box-shadow: none; }

.tws-article-list--list .tws-article-list--price .tws-api--price-both-shown {
  color: #000; }

.tws-article-list--list .tws-article-list--labels .tws-article-labels--special-offer .tws-article-labels--label-text {
  background-color: #000; }

@media only screen and (min-width: 1100px) {
  .hero-img .tws-content-box--cell {
    opacity: 0; }
  .tws-content-box:hover .tws-content-box--cell {
    opacity: 1;
    transition: all .4s; } }

@media only screen and (max-width: 1100px) {
  .bountiful--body .bountiful--body-wrapper .bountiful--main .bountiful--main-wrapper {
    padding-bottom: 30px; } }

@media only screen and (min-width: 1100px) {
  .tws-article-list--list .tws-article-list--buy-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 35%; }
  .tws-article-list--list .tws-article-list--article-name .tws-util-heading--heading {
    margin-bottom: 1rem; }
  .tws-article-list--list .tws-list--type-grid .tws-list--grid-item {
    margin-bottom: 10px; } }

.tws-article-list--introduction-text {
  margin-bottom: 1rem; }

.tws-article-list--list .tws-article-list--introduction-text {
  font-size: 13px; }

/* ------ HEADER ------ */
.bountiful--header {
  font-family: "Lato", serif !important;
  font-size: 12px; }

@media only screen and (min-width: 1100px) {
  ul.nav {
    display: flex;
    justify-content: center;
    margin-left: 195px !important;
    margin-right: 195px !important;
    border-bottom: 1px solid black; } }

.slot--header-horizontal-menu .navbar-nav > li a {
  font-weight: normal;
  letter-spacing: 1.6px;
  color: #000;
  font-size: 12px; }

.slot--header-horizontal-menu .navbar-nav .dropdown-menu li a {
  letter-spacing: 1.6px;
  color: #000;
  font-size: 12px; }

@media only screen and (max-width: 768px) {
  .slot--header-logo {
    width: 60px; } }

.slot--header-cart .tws-mini-cart--header-cart-button .tws-mini-cart--header-cart-info .tws-mini-cart--header-quantity {
  font-size: 12px; }

div.slot--header-search input.form-control {
  border-radius: 0;
  border: 1px solid #000;
  color: #000; }
  div.slot--header-search input.form-control::placeholder {
    color: #999; }

/* ------ MENU on mobile, switching order of menu and products menu------ */
@media only screen and (max-width: 1100px) {
  .bountiful--sidebar .bountiful--sidebar-wrapper {
    display: flex;
    flex-direction: column; }
  .bountiful--sidebar .bountiful--sidebar-controls {
    order: 1; }
  .bountiful--sidebar-mobile-menu {
    order: 3; }
  .slot--sidebar {
    order: 2; }
  .bountiful--sidebar .tws-vertical-menu-light .tws-vertical-menu-light-root-level {
    padding: 10px 0 0 0; } }

.slot--header-cart .tws-mini-cart--header-cart-button {
  color: #000; }

.bountiful--body .bountiful--body-wrapper .bountiful--main .bountiful--main-wrapper {
  padding-top: 15px; }

.slot--header-cart .tws-mini-cart--mini-cart-dropdown-container {
  box-shadow: none;
  border: 1px solid #000; }

.tws-container--tws-articles-two-type-carousel .thumbnail {
  border-radius: 0; }

.form-control {
  border-radius: 0; }

.modal .modal-dialog .modal-content {
  border-radius: 0; }

.tws-opc-cart--rows .tws-opc-cart--item .tws-opc-cart--item-wrapper .tws-opc-cart--item-quantity button,
.tws-opc-cart--rows .tws-opc-cart--item .tws-opc-cart--item-wrapper .tws-opc-cart--item-quantity input,
.tws-opc-cart--rows .tws-opc-cart--item .tws-opc-cart--item-wrapper .tws-opc-cart--item-remove button {
  border-radius: 0; }

.slot--header-cart .tws-mini-cart--mini-cart-dropdown-container {
  border-radius: 0; }

.btn {
  border-radius: 0; }

.btn-default {
  border-radius: 0; }

button span.ladda-label {
  font-weight: normal;
  color: #000; }

button.ladda-button {
  background-color: transparent;
  border: 1px solid #000;
  color: #000; }

.tws-article-list--list .tws-article-list--buy-button .btn .ladda-label,
.tws-article-list--list .tws-article-list--buy-button .btn .tws-article-list--choices-button-text,
.tws-article-list--list .tws-article-list--buy-button .btn .tws-article-list--show-more-button-text {
  color: #000; }

.tws-article-list--list .tws-article-list--buy-button .btn:hover,
.tws-article-list--list .tws-article-list--buy-button .btn:focus {
  background: transparent;
  border-bottom: 1px solid #000; }

.tws-article-list--list .tws-article-list--buy-button .btn {
  background: transparent;
  border: 1px solid #000;
  color: #000;
  border-radius: 0; }

.tws-article-buy-button-with-modal--buy-button {
  color: #000; }

.tws-article-list--list .tws-article-list--buy-button .btn.tws-article-buy-button-with-modal--buy-button:after,
.tws-article-display .tws-article-display--buy-button .btn.tws-article-buy-button-with-modal--buy-button:after {
  content: none; }

.tws-article-list--list .tws-article-list--buy-button .btn.tws-article-list--show-more-button:after {
  content: none; }

.btn-primary:hover,
.btn-primary:focus {
  background-color: transparent;
  color: #000; }

.tws-article-list--list .tws-list--type-grid .thumbnail .caption {
  box-shadow: none; }

.tws-article-list--list .tws-article-list--buy-button .btn .ladda-label,
.tws-article-list--list .tws-article-list--buy-button .btn .tws-article-list--choices-button-text,
.tws-article-list--list .tws-article-list--buy-button .btn .tws-article-list--show-more-button-text {
  font-weight: normal; }

.bountiful--article-images .slot--article-labels .tws-article-labels--special-offer .tws-article-labels--label-text {
  border: 1px solid #000;
  background: #000; }

.slot--article-price .tws-api--price-current.tws-api--price-both-shown {
  color: #000; }

.btn-default:hover,
.btn-default:focus {
  background: #000; }

.modal-content .btn-primary {
  border: 1px solid #000;
  border-bottom: 2px solid #000; }

@media only screen and (min-width: 1100px) {
  .slot--header-horizontal-menu {
    border-top: none; }
  .bountiful--header {
    box-shadow: none;
    position: sticky;
    z-index: 999;
    top: 0; }
  .tws-audience-selector--frame-container {
    z-index: 99999; }
  .slot--header-search input.form-control {
    width: 200px; }
  .slot--header-search .tws-article-search--no-label {
    color: #000; }
  .bountiful--top-bar .slot--top-bar-audience-selector {
    margin-top: 0; } }

.slot--header-shipping-notice {
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 0;
  padding: 5px 15px; }

.tws-mini-cart--open,
.bountiful--top-bar .slot--top-bar-audience-selector .tws-audience-selector .tws-audience-selector--frame-container .tws-audience-selector--frame {
  border-radius: 0; }

.slot--header-cart .tws-mini-cart--mini-cart-dropdown-container:before {
  border-bottom-color: #000; }

.fa-gear:before,
.fa-cog:before {
  display: none; }

.tws-mini-cart--header-cart-text {
  text-transform: uppercase; }

@media only screen and (min-width: 1100px) {
  .bountiful--header .bountiful--header-wrapper {
    padding: 40px 30px; }
  .bountiful--body .bountiful--body-wrapper {
    padding-top: 0; }
  .slot--header-shipping-notice {
    bottom: 30px; } }

.slot--header-horizontal-menu .navbar-nav > li a:hover {
  color: #898989; }

.slot--header-search input.form-control {
  border-radius: 0; }

.slot--header-search .tws-article-search .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  border: none; }

@media only screen and (min-width: 1100px) {
  .slot--header-cart .tws-mini-cart--header-cart-button .tws-mini-cart--header-cart-info .tws-mini-cart--header-cart-img:before {
    content: none; } }

@media only screen and (min-width: 1100px) {
  .startpage .bountiful--sidebar {
    display: none; }
  .startpage .bountiful--body .bountiful--body-wrapper .bountiful--main {
    width: 100%; } }

.tws-vertical-menu-light--title,
.bountiful--sidebar .tws-articles-two .tws-articles-two--heading {
  border-top: 0; }

.tws-vertical-menu-light-root-level li:last-child {
  display: none !important; }

.tws-vertical-menu-light-root-level li:nth-child(13) {
  display: none !important; }

@media only screen and (min-width: 1100px) {
  .bountiful--sidebar .tws-vertical-menu-light {
    border-radius: 0;
    box-shadow: none; } }

.big-screen-search {
  display: block; }

.caret {
  display: none; }

/* ------ FOOTER ------ */
.bountiful--footer {
  font-family: "Lato", serif !important;
  font-size: 12px; }

.bountiful--footer {
  margin-top: 0; }
  .bountiful--footer button.ladda-button {
    border: 1px solid #fff !important;
    border-left: none !important;
    color: #fff;
    border-radius: 0; }
  .bountiful--footer .tws-newsletter-subscribe .form-group .input-group .form-control {
    margin-right: 0;
    border: 1px solid #fff !important;
    border-radius: 0;
    background-color: #000;
    color: #fff;
    border-right: none !important; }

.bountiful--footer-mid-right {
  display: none; }

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .bountiful--footer > .container > .row {
    display: flex;
    flex-direction: column; }
  .bountiful--footer-right {
    margin: 0 auto;
    text-align: center; }
  .bountiful--footer-left {
    text-align: center; } }

@media only screen and (min-width: 1100px) {
  .bountiful--footer h3 {
    margin-bottom: 0.7em;
    letter-spacing: 2.5px; }
  .bountiful--footer p {
    line-height: 0.7;
    letter-spacing: 0.3px;
    margin-bottom: 10px; }
  .bountiful--footer .company {
    margin-bottom: 4px; }
    .bountiful--footer .company p {
      line-height: 1.5;
      letter-spacing: 0.3px;
      margin-bottom: 2px; }
  .bountiful--footer .icons {
    margin: 2rem 0; }
  .bountiful--footer .media {
    margin-bottom: 3rem; }
    .bountiful--footer .media h3:first-child a {
      letter-spacing: 2.5px; }
    .bountiful--footer .media a {
      line-height: 1.5;
      letter-spacing: 0.6px; }
  .bountiful--footer .partners a {
    line-height: 1.5;
    letter-spacing: 0.6px; } }

.bountiful--footer h3 {
  text-transform: uppercase; }

.bountiful--footer .media {
  display: flex;
  flex-direction: column; }
  .bountiful--footer .media a {
    margin-bottom: 2px;
    text-transform: uppercase; }

.bountiful--footer .partners {
  display: flex;
  flex-direction: column; }
  .bountiful--footer .partners a {
    margin-bottom: 2px;
    text-transform: uppercase; }

.bountiful--footer .icons .facebook {
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20id%3D%22Page-1%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22social-facebook%22%20fill%3D%22%23000%22%3E%3Cg%20id%3D%22Group-3%22%3E%3Cpath%20d%3D%22M19.96%2017.537l.36-2.775h-2.75V12.99c0-.804.225-1.352%201.377-1.352h1.468V9.155a19.483%2019.483%200%200%200-2.14-.11c-2.12%200-3.57%201.295-3.57%203.67v2.047h-2.397v2.775h2.397v7.12h2.866v-7.12h2.39z%22%20id%3D%22Fill-16%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat; }

.bountiful--footer .icons .instagram {
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20id%3D%22Page-1%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22social-instagram%22%20fill%3D%22%23000%22%3E%3Cg%20id%3D%22Group%22%3E%3Cpath%20d%3D%22M10.797%209h10.407A1.8%201.8%200%200%201%2023%2010.797v10.407A1.8%201.8%200%200%201%2021.204%2023H10.797A1.803%201.803%200%200%201%209%2021.204V10.797C9%209.807%209.81%209%2010.797%209zm8.402%201.555a.63.63%200%200%200-.63.63v1.51c0%20.346.28.63.63.63h1.58c.34%200%20.63-.284.63-.63v-1.51c0-.346-.29-.63-.63-.63H19.2zm2.21%204.365h-1.23c.11.382.18.784.18%201.202%200%202.33-1.95%204.217-4.36%204.217s-4.35-1.89-4.35-4.22c0-.42.06-.82.18-1.2h-1.28v5.91c0%20.3.25.55.55.55h9.75c.31%200%20.55-.25.55-.56v-5.91zM16%2013.245c-1.55%200-2.81%201.22-2.81%202.725%200%201.504%201.26%202.724%202.81%202.724%201.553%200%202.812-1.22%202.812-2.724%200-1.506-1.26-2.725-2.813-2.725z%22%20id%3D%22Fill-24%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  margin-left: 1rem; }

.bountiful--footer .icons span {
  background-color: #fff;
  color: #000;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%; }

.bountiful--footer .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .bountiful--footer .middle .footer-logo {
    margin-top: 4rem;
    width: 100px;
    height: auto; }
    .bountiful--footer .middle .footer-logo img {
      width: 100%;
      height: auto; }
  .bountiful--footer .middle .tws-newsletter-subscribe {
    width: 100%; }
  .bountiful--footer .middle .newsletter-new {
    width: 100%; }
    .bountiful--footer .middle .newsletter-new p {
      margin-bottom: 1rem; }
  .bountiful--footer .middle .tws-newsletter-subscribe--html-before {
    text-align: center;
    text-transform: uppercase; }

@media only screen and (min-width: 1100px) {
  .bountiful--footer {
    margin-top: 40px;
    padding-bottom: 40px; }
  .bountiful--footer .container > .row {
    display: flex; }
  .bountiful--footer-left {
    flex-grow: 1; }
  .bountiful--footer-mid-left {
    flex-grow: 5;
    display: flex;
    align-items: center; }
  .bountiful--footer-right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end; } }

@media only screen and (max-width: 1100px) {
  .bountiful--top-bar {
    display: none; } }

@media only screen and (max-width: 1100px) {
  .bountiful--footer .middle .footer-logo {
    margin-top: 2rem; } }

@media only screen and (max-width: 1100px) and (min-width: 768px) {
  .bountiful--footer .bountiful--footer-left,
  .bountiful--footer .bountiful--footer-mid-left {
    margin: 0 auto 30px auto; } }

/* ------ CUSTOM PAGE ------ */
.bountiful--content h1,
.tws-util-heading h1 {
  font-size: 20px; }

.bountiful--content h2,
.tws-util-heading h2 {
  font-size: 18px; }

.bountiful--content h3,
.tws-util-heading h3 {
  font-size: 16px; }

.bountiful--content h4,
.tws-util-heading h4 {
  font-size: 14px; }

.bountiful--content h5,
.tws-util-heading h5 {
  font-size: 12px; }

/* ------ ARTICLE ------ */
@media only screen and (max-width: 768px) {
  .bountiful--buy-button-group .slot--article-buy-button {
    float: none !important; }
  .slot--article-quantity .tws-article-quantity--input-wrapper input.form-control {
    margin-bottom: 10px; } }

.bountiful--article-images .slot--article-images .tws-article-images--current-image {
  border: none; }

.bountiful--article-images .tws-article-images--slick-nav:before,
.bountiful--article-images .tws-article-images--slick-nav:after {
  content: none; }

.bountiful--article-links {
  display: none; }

.slot--article-description {
  border: none;
  padding-top: 10px; }

.slot--article-description .tws-article-description .tws-article-description--heading {
  display: none; }

.slot--article-description .tws-article-description .tws-article-description--article-number {
  display: none; }

.slot--article-price .tws-api--price-current {
  font-weight: normal; }

@media only screen and (min-width: 1100px) {
  .slot--article-quantity .tws-article-quantity--input-wrapper .input-group span {
    display: none; }
  .slot--article-quantity .tws-article-quantity--input-wrapper {
    margin-bottom: 0 !important; }
  .bountiful--buy-button-group {
    display: flex;
    align-items: center; }
  .slot--article-quantity .tws-article-quantity--input-wrapper input.form-control {
    margin: 0; } }

.bountiful--buy-button-group .slot--article-buy-button .btn .ladda-label {
  font-size: 0; }
  .bountiful--buy-button-group .slot--article-buy-button .btn .ladda-label:before {
    content: "LÄGG I VARUKORGEN";
    font-size: 14px;
    font-family: 'Palatino Linotype', serif;
    letter-spacing: 0.5px;
    text-rendering: auto;
    font-size: 12px; }

.fa-search-plus:before {
  content: none; }

.tws-articlegroups .thumbnail,
.tws-articlegroup-list .thumbnail {
  border: none;
  box-shadow: none; }

.tws-articlegroups .thumbnail .tws-articlegroup-list--grid-img-wrapper .tws-img--wrapper,
.tws-articlegroup-list .thumbnail .tws-articlegroup-list--grid-img-wrapper .tws-img--wrapper {
  border-bottom: none; }

.bountiful--buy-button-group .slot--article-buy-button .btn:after {
  content: none; }

@media only screen and (min-width: 550px) {
  .bountiful--buy-button-group .slot--article-buy-button .btn {
    margin-bottom: 10px; } }

.tws-article-introduction--text .tws-article-introduction--read-more {
  display: none; }

.bountiful--buy-button-group .slot--article-buy-button .btn .ladda-spinner::before {
  content: "LÄGG I VARUKORGEN"; }

.tws-article-introduction--text {
  margin: 5px 0 20px 0; }

.slot--article-name h1 {
  margin-bottom: 5px; }

/* ------ CHECKOUT ------ */
.slot--header-back-button .btn.btn-default {
  border-radius: 0; }

.tws-opc-cart--wrapper,
.slot--checkout-introduction-text,
.opc-checkout--shortcuts,
.tws-checkout--select-delivery-wrapper .tws-checkout--select-delivery-list-wrapper,
.tws-checkout--select-delivery-wrapper .tws-checkout--select-payment-list-wrapper,
.tws-checkout--select-payment-wrapper .tws-checkout--select-delivery-list-wrapper,
.tws-checkout--select-payment-wrapper .tws-checkout--select-payment-list-wrapper,
.tws-checkout--checkout-container {
  box-shadow: none; }

.tws-cart-summary--wrapper,
.slot--checkout-cart-usps {
  box-shadow: none; }

.tws-opc-cart--wrapper {
  box-shadow: none; }

.slot--checkout-related.tws-container--tws-articles-two-type-carousel .tws-article-list--list .tws-list--grid-item .thumbnail {
  border: 1px solid #000; }

.slot--checkout-introduction-text {
  border-radius: 0; }

.opc-checkout--shortcuts {
  box-shadow: none; }

.bountiful--sidebar .tws-vertical-menu-light,
.tws-checkout--select-delivery-wrapper .tws-checkout--select-delivery-list-wrapper,
.tws-checkout--select-delivery-wrapper .tws-checkout--select-payment-list-wrapper,
.tws-checkout--select-payment-wrapper .tws-checkout--select-delivery-list-wrapper,
.tws-checkout--select-payment-wrapper .tws-checkout--select-payment-list-wrapper,
.tws-checkout--checkout-container {
  box-shadow: none; }

.tws-opc-cart--heading span {
  font-size: 11px;
  text-transform: uppercase; }

.twsPriceCurrent {
  letter-spacing: 0.2px; }

.slot--checkout-related.tws-container--tws-articles-two-type-carousel .tws-article-list--list .tws-list--grid-item .thumbnail {
  box-shadow: none;
  border-radius: 0; }

.tws-opc-cart--rows .tws-opc-cart--item .tws-opc-cart--item-image .tws-img--wrapper,
.tws-opc-cart--rows .tws-opc-cart--item .tws-opc-cart--item-wrapper .tws-opc-cart--item-quantity input,
.tws-opc-cart--rows .tws-opc-cart--item .tws-opc-cart--item-wrapper .tws-opc-cart--item-quantity button,
.tws-opc-cart--rows .tws-opc-cart--item .tws-opc-cart--item-wrapper .tws-opc-cart--item-remove button {
  border-radius: 0; }

.bountiful--top-bar .slot--top-bar-audience-selector .tws-audience-selector .tws-audience-selector--frame-container .tws-audience-selector--frame-right-content .tws-audience-selector--selection-box .tws-audience-selector--selection-box-content,
.bountiful--top-bar .slot--top-bar-audience-selector .tws-audience-selector .tws-audience-selector--frame-container .tws-audience-selector--frame-right-content .tws-audience-selector--selection-box .tws-audience-selector--selection-box-content {
  border-radius: 0 !important; }

.tws-opc-cart--cart-heading,
.opc-checkout--related .tws-articles-two--heading .tws-util-heading--heading,
.opc-checkout--summary h2,
.tws-cart-summary--header,
.tws-checkout--select-delivery-heading,
.tws-checkout--select-payment-heading,
.tws-checkout--complete-heading {
  font-size: 18px; }

.tws-opc-cart--cart-heading,
.tws-checkout--select-delivery-heading,
.tws-checkout--complete-heading,
.tws-cart-summary--header {
  text-transform: uppercase; }

.opc-checkout--cart,
.opc-checkout--related,
.opc-checkout--checkout-method,
.tws-checkout--select-delivery {
  margin-bottom: 5rem; }

.opc-checkout--shortcuts {
  display: none; }

/* ------ OTHER PAGES ------ */
@media only screen and (min-width: 1100px) {
  .bountiful--sidebar .tws-articles-two .tws-articles-two--list {
    padding: 15px 0; }
  .bountiful--sidebar .tws-vertical-menu-light .tws-vertical-menu-light--title,
  .bountiful--sidebar .tws-articles-two .tws-articles-two--heading {
    letter-spacing: 1px;
    border-bottom: none; } }

.bountiful--sidebar .tws-articles-two .tws-articles-two--heading,
.bountiful--sidebar .tws-articles-two .tws-articles-two--list,
.using-mouse .tws-article-list--list .tws-list--type-list .tws-list--list-item:hover {
  box-shadow: none; }

.bountiful--sidebar .tws-vertical-menu-light .tws-vertical-menu-light-root-level li {
  border-bottom: none; }

.tws-article-list--list .tws-list--type-list .tws-list--list-item {
  border: none; }

.bountiful--sidebar .tws-container--tws-custom-html {
  box-shadow: none; }

.tws-article-description--text-container {
  max-height: none; }

.tws-article-display .tws-article-description--show-more {
  display: none; }

@media only screen and (min-width: 1100px) {
  .articlegroup-5801265 .bountiful--sidebar,
  .articlegroup-2382579 .bountiful--sidebar,
  .custompage-7999702 .bountiful--sidebar,
  .custompage-8017130 .bountiful--sidebar,
  .custompage-8052060 .bountiful--sidebar,
  .custompage-8052049 .bountiful--sidebar,
  .custompage-8052054 .bountiful--sidebar,
  .articlegroup-2382840 .bountiful--sidebar,
  .articlegroup-5022850 .bountiful--sidebar,
  .articlegroup-2382842 .bountiful--sidebar,
  .articlegroup-2389045 .bountiful--sidebar,
  .articlegroup-5310675 .bountiful--sidebar,
  .articlegroup-4797346 .bountiful--sidebar,
  .articlegroup-4804152 .bountiful--sidebar,
  .articlegroup-2382822 .bountiful--sidebar,
  .articlegroup-4796256 .bountiful--sidebar,
  .custompage-8052038 .bountiful--sidebar,
  .contactform .bountiful--sidebar {
    display: none; }
  .articlegroup-5801265 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-2382579 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-7999702 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-8017130 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-8052060 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-8052049 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-8052054 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-2382840 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5022850 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-2382842 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-2389045 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5310675 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-4797346 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-4804152 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-2382822 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-4796256 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-8052038 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .contactform .bountiful--body .bountiful--body-wrapper .bountiful--main {
    width: 100%; } }

@media only screen and (min-width: 1100px) {
  .articlegroup-5801265 .slot--breadcrumbs,
  .custompage-7999702 .slot--breadcrumbs,
  .custompage-8017130 .slot--breadcrumbs,
  .articlegroup-5806899 .slot--breadcrumbs,
  .articlegroup-5806907 .slot--breadcrumbs,
  .custompage-8052049 .slot--breadcrumbs,
  .articlegroup-5806913 .slot--breadcrumbs,
  .articlegroup-5806927 .slot--breadcrumbs,
  .custompage-8052054 .slot--breadcrumbs,
  .articlegroup-5806931 .slot--breadcrumbs,
  .articlegroup-5806933 .slot--breadcrumbs,
  .articlegroup-5806945 .slot--breadcrumbs,
  .articlegroup-5806949 .slot--breadcrumbs,
  .custompage-8052050 .slot--breadcrumbs,
  .custompage-8052038 .slot--breadcrumbs,
  .custompage-8052060 .slot--breadcrumbs,
  .custompage-8052044 .slot--breadcrumbs,
  .contactform .slot--breadcrumbs {
    display: none; } }

.articlegroup-5801265 .customPageArticleSelection,
.custompage-7999702 .customPageArticleSelection,
.custompage-8017130 .customPageArticleSelection,
.articlegroup-5806899 .customPageArticleSelection,
.articlegroup-5806907 .customPageArticleSelection,
.custompage-8052049 .customPageArticleSelection,
.articlegroup-5806913 .customPageArticleSelection,
.articlegroup-5806927 .customPageArticleSelection,
.custompage-8052054 .customPageArticleSelection,
.articlegroup-5806931 .customPageArticleSelection,
.articlegroup-5806933 .customPageArticleSelection,
.articlegroup-5806945 .customPageArticleSelection,
.articlegroup-5806949 .customPageArticleSelection,
.custompage-8052050 .customPageArticleSelection,
.custompage-8052038 .customPageArticleSelection,
.custompage-8052060 .customPageArticleSelection,
.custompage-8052044 .customPageArticleSelection,
.contactform .customPageArticleSelection {
  display: none; }

@media only screen and (min-width: 1100px) {
  .articlegroup-4804152 .slot--breadcrumbs,
  .articlegroup-4797346 .slot--breadcrumbs,
  .articlegroup-4796256 .slot--breadcrumbs,
  .articlegroup-2389045 .slot--breadcrumbs,
  .articlegroup-2382842 .slot--breadcrumbs,
  .articlegroup-5310675 .slot--breadcrumbs,
  .articlegroup-2382822 .slot--breadcrumbs,
  .articlegroup-2382579 .slot--breadcrumbs,
  .articlegroup-2382840 .slot--breadcrumbs,
  .articlegroup-5022850 .slot--breadcrumbs {
    display: none; } }

@media only screen and (min-width: 1100px) {
  .articlegroup-5806899 .bountiful--sidebar,
  .articlegroup-5806907 .bountiful--sidebar,
  .articlegroup-5806913 .bountiful--sidebar,
  .articlegroup-5806927 .bountiful--sidebar,
  .articlegroup-5806933 .bountiful--sidebar,
  .articlegroup-5806945 .bountiful--sidebar,
  .articlegroup-5806949 .bountiful--sidebar,
  .articlegroup-5806931 .bountiful--sidebar,
  .custompage-8052050 .bountiful--sidebar,
  .custompage-8052044 .bountiful--sidebar,
  .articlegroup-5949375 .bountiful--sidebar {
    display: none; }
  .articlegroup-5806899 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5806907 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5806913 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5806927 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5806933 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5806945 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5806949 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5806931 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-8052050 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .custompage-8052044 .bountiful--body .bountiful--body-wrapper .bountiful--main,
  .articlegroup-5949375 .bountiful--body .bountiful--body-wrapper .bountiful--main {
    width: 100%; } }

/* ------ VISIT US ------ */
.container-boxes-custom {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media only screen and (min-width: 1100px) {
    .container-boxes-custom {
      margin-left: -15px; } }
  .container-boxes-custom .box-1,
  .container-boxes-custom .box-2,
  .container-boxes-custom .box-3,
  .container-boxes-custom .box-4,
  .container-boxes-custom .box-5,
  .container-boxes-custom .box-6 {
    margin: 15px 5px; }
    .container-boxes-custom .box-1 div,
    .container-boxes-custom .box-2 div,
    .container-boxes-custom .box-3 div,
    .container-boxes-custom .box-4 div,
    .container-boxes-custom .box-5 div,
    .container-boxes-custom .box-6 div {
      width: 300px;
      height: 300px; }
      .container-boxes-custom .box-1 div img,
      .container-boxes-custom .box-2 div img,
      .container-boxes-custom .box-3 div img,
      .container-boxes-custom .box-4 div img,
      .container-boxes-custom .box-5 div img,
      .container-boxes-custom .box-6 div img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .container-boxes-custom .box-1 > a,
  .container-boxes-custom .box-2 > a,
  .container-boxes-custom .box-3 > a,
  .container-boxes-custom .box-4 > a,
  .container-boxes-custom .box-5 > a,
  .container-boxes-custom .box-6 > a {
    font-size: 14px;
    text-decoration: none;
    line-height: 3; }

.container-park .col-sm-8 > p,
.container-bike .col-sm-8 > p {
  margin-top: 0; }

.container-kontakt {
  margin-top: 5rem; }
  .container-kontakt .col-sm-6 > h2 {
    margin-top: 0; }

/* ------ WE ARE MALERAS ------ */
.container-boxes-artists {
  margin-left: -15px;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .container-boxes-artists .art {
    margin: 20px 15px; }
    .container-boxes-artists .art div {
      width: 150px;
      height: 150px;
      margin-bottom: 1rem; }
      .container-boxes-artists .art div img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .container-boxes-artists .art .tws-article-list--list .tws-article-list--buy-button {
      position: absolute;
      left: 10px;
      bottom: 0px;
      width: 35%; }
    .container-boxes-artists .art a {
      text-decoration: none; }
  .container-boxes-artists .opc-checkout--related {
    display: none; }
  .container-boxes-artists .bountiful--buy-button-group .slot--article-buy-button {
    display: none !important; }

@media only screen and (min-width: 1100px) {
  html:lang(en) .caption {
    position: relative; }
  html:lang(en) .tws-article-list--introduction-text {
    position: absolute;
    top: 80px; } }

html:lang(en) .tws-article-list--list .tws-article-list--buy-button {
  display: none; }

html:lang(en) .bountiful--buy-button-group {
  display: none; }

html:lang(en) .bountiful--top-bar {
  display: none; }

html:lang(en) .slot--header-cart {
  display: none; }

html:lang(en) .bountiful--article-choices-wrapper {
  display: none; }

html:lang(en) .bountiful--buy-button-group .slot--article-buy-button .btn {
  display: none; }

.slot--header-shipping-notice {
  display: none; }

.tws-list--highlight {
  animation: none; }
